export const can_approval_page_view = 'can_approval_page_view';
export const can_user_page_view = 'can_user_page_view';
export const can_task_page_view = 'can_task_page_view';

export const can_admin_comment_edit = 'can_admin_comment_edit';
export const can_admin_comment_delete = 'can_admin_comment_delete';

export const can_category_create = 'can_category_create';
export const can_category_edit = 'can_category_edit';
export const can_category_delete = 'can_category_delete';

export const can_article_create = 'can_article_create';
export const can_article_edit = 'can_article_edit';
export const can_article_delete = 'can_article_delete';

export const can_article_admin_chat_view = 'can_article_admin_chat_view';

export const can_article_on_approval = 'can_article_on_approval';
export const can_article_on_actualization = 'can_article_on_actualization';
export const can_article_agreed_by_user = 'can_article_agreed_by_user';

export const can_im_create = 'can_info_message_create';
export const can_im_edit = 'can_info_message_edit';
export const can_im_delete = 'can_info_message_delete';

export const can_im_admin_chat_view = 'can_info_message_admin_chat_view';

export const can_im_on_approval = 'can_info_message_on_approval';
export const can_im_agreed = 'can_info_message_agreed_by_user';

export const can_new_create = 'can_new_create';
export const can_new_edit = 'can_new_edit';

export const can_bag_report_page_view = 'can_bag_report_page_view';

export const can_report_page_view = 'can_report_page_view';
export const can_function_page_view = 'can_function_page_view';

export const can_trash_page_view = 'can_trash_page_view';

export const can_request_info_edit = 'can_request_info_edit';
export const can_request_info_delete = 'can_request_info_delete';
export const can_request_info_admin = 'can_request_info_admin';

export const can_request_info_page_view = 'can_request_info_page_view';

// export const can_temp_storage_page_view = 'can_temp_storage_page_view';
