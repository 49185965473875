import {
    GET_REQUEST_START,
    CREATE_REQUEST_START,
    UPDATE_REQUEST_START,
    UPDATE_REQUEST_PAGE,
    GET_REQUEST_COUNT_START,
    CLEAR_REQUEST_DATA,
} from './actionTypes';

export const updateRequestPage = (payload) => ({
    type: UPDATE_REQUEST_PAGE,
    payload,
});

export const getRequestDetails = (payload) => ({
    type: GET_REQUEST_START,
    payload,
});

export const createRequest = (payload) => ({
    type: CREATE_REQUEST_START,
    payload,
});

export const updateRequest = (payload) => ({
    type: UPDATE_REQUEST_START,
    payload,
});

export const getRequestCount = (payload) => ({
    type: GET_REQUEST_COUNT_START,
    payload,
});

export const clearRequestDetails = () => ({ type: CLEAR_REQUEST_DATA });
