import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getArticlesList,
    setEmptyArticlesList,
} from '../../../../actions/articlesAction';
import {
    getLettersList,
    setEmptyLettersList,
} from '../../../../actions/lettersAction';
import {
    getMediaAttachmentsList,
    setEmptyMediaAttachmentsList,
} from '../../../../actions/mediaAction';
import Loading from '../../../../components/CommonParts/Loading';
import ResultGroupComponent from '../ResultGroup';
import { toMessageDate, toFilterDate } from '../../../../helpers/m';
import { ReactComponent as BookIcon } from '../../../../assets/icons/course.svg';
import { ReactComponent as LetterIcon } from '../../../../assets/icons/letter.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/icons/video.svg';
import styles from './searchResults.module.scss';

const GROUPS = {
    letters: {
        itemIcon: <LetterIcon />,
        itemTitle: 'about',
        subtitleRenderer: (item) => (
            <div className={styles.itemSubtext}>
                <span>{item.get_number}</span>
                <span>{item.get_access_type_display}</span>
                <span>{item.geography?.name}</span>
                <span>{item.recipient}</span>
                <span>{toMessageDate(item.plan_public_date)}</span>
            </div>
        ),
        linkGenerate: (item) => `/letter/${item.id}`,
    },
    articles: {
        itemIcon: <BookIcon />,
        itemTitle: 'title',
        subtitleRenderer: (item) => (
            <div className={styles.itemSubtext}>
                {item.first_category_name && (
                    <span>{`База знаний / ${item.first_category_name}`}</span>
                )}
                <span>{toMessageDate(item.change_dt)}</span>
            </div>
        ),
        linkGenerate: (item) =>
            `/knowledgebase/categories/${item.first_category_id}/articles/${item.id}`,
    },
    media: {
        itemIcon: <VideoIcon />,
        itemTitle: 'name',
        subtitleRenderer: (item) => (
            <div className={styles.itemSubtext}>
                <span>{item.article.title}</span>
                <span>{`${
                    Math.round((item.size / 1024 / 1024) * 100) / 100
                } Мб`}</span>
            </div>
        ),
        linkGenerate: (item) =>
            `/knowledgebase/categories/${item.article.first_category_id}/articles/${item.article.id}`,
    },
};

const SearchResultsComponent = ({ filters, closeResults }) => {
    const dispatch = useDispatch();
    const articlesList = useSelector(
        (state) => state.articlesReducer.searchedList,
    );
    const lettersList = useSelector(
        (state) => state.lettersReducer.searchedList,
    );
    const mediaList = useSelector((state) => state.mediaReducer.searchedList);
    const articlesFetching = useSelector(
        (state) => state.articlesReducer.fetching,
    );
    const lettersFetching = useSelector(
        (state) => state.lettersReducer.fetching,
    );
    const mediaFetching = useSelector((state) => state.mediaReducer.fetching);
    const fetching = articlesFetching || lettersFetching || mediaFetching;
    const totalResults =
        lettersList?.count + mediaList?.count + articlesList?.count;

    const makeUrlParams = (filters) => {
        const query = new URLSearchParams();

        query.set('page', filters?.page);
        query.set('page_size', filters?.page_size);
        if (filters?.q) {
            query.set('q', filters.q);
        }
        if (filters?.q && filters.source && filters.source.body) {
            query.set('q_content', filters?.q);
        }
        if (filters.type && filters.type.articles) {
            query.set('type', 'article');
        }
        if (filters.type && filters.type.projects) {
            query.set('type', 'project');
        }
        if (filters.type && filters.type.articles && filters.type.projects) {
            query.delete('type');
        }
        if (filters.functions && filters.functions.length) {
            query.set(
                'function_id',
                filters.functions.map((f) => f.id),
            );
        }

        return query;
    };

    const clearResults = () => {
        dispatch(setEmptyArticlesList());
        dispatch(setEmptyLettersList());
        dispatch(setEmptyMediaAttachmentsList());
    };

    useEffect(() => {
        if (!filters) return;
        if (typeof filters !== 'object') return;
        if (!Object.keys(filters).length) return;

        const searchOnlyIM = !!filters.initiator?.id || !!filters.author?.id;

        if (
            filters?.q?.length > 0 ||
            filters?.author?.id ||
            filters?.initiator?.id ||
            filters?.functions[0] ||
            filters?.date_from ||
            filters?.date_to
        ) {
            clearResults();

            const query = makeUrlParams(filters);
            if (
                !searchOnlyIM &&
                filters.type &&
                (filters?.q?.length > 0 ||
                    filters?.date_from ||
                    filters?.date_to) &&
                (filters.type.articles || filters.type.projects)
            ) {
                if (filters.date_from) {
                    query.set(
                        'change_date_gte',
                        toFilterDate(filters.date_from),
                    );
                }
                if (filters.date_to) {
                    query.set('change_date_lte', toFilterDate(filters.date_to));
                }
                dispatch(getArticlesList(query.toString()));
                query.delete('type');
            }
            if (
                filters.type &&
                filters.type.letters &&
                (filters?.q?.length > 0 ||
                    filters?.date_from ||
                    filters?.date_to ||
                    filters?.author?.id ||
                    filters?.initiator?.id)
            ) {
                if (filters.date_from) {
                    query.delete('change_date_gte');
                    query.set(
                        'plan_public_date_gte',
                        toFilterDate(filters.date_from),
                    );
                }
                if (filters.date_to) {
                    query.delete('change_date_lte');
                    query.set(
                        'plan_public_date_lte',
                        toFilterDate(filters.date_to),
                    );
                }
                if (filters.initiator?.id) {
                    query.set('initiator_id', filters.initiator.id);
                    query.delete('initiator');
                }
                if (filters.author?.id) {
                    query.set('author_id', filters.author.id);
                    query.delete('author');
                }
                dispatch(getLettersList(query.toString()));
                query.delete('initiator_id');
                query.delete('author_id');
            }
            if (!searchOnlyIM && filters.source && filters.source.attach) {
                dispatch(getMediaAttachmentsList(query.toString()));
            }
        }
    }, [filters]);

    const loadMore = (type) => {
        let query;
        switch (type) {
            case 'letters':
                query = makeUrlParams({
                    ...filters,
                    page: Number(lettersList.page) + 1,
                });
                if (filters.date_from) {
                    query.delete('change_date_gte');
                    query.set(
                        'plan_public_date_gte',
                        toFilterDate(filters.date_from),
                    );
                }
                if (filters.date_to) {
                    query.delete('change_date_lte');
                    query.set(
                        'plan_public_date_lte',
                        toFilterDate(filters.date_to),
                    );
                }
                if (filters.initiator?.id) {
                    query.set('initiator_id', filters.initiator.id);
                    query.delete('initiator');
                }
                if (filters.author?.id) {
                    query.set('author_id', filters.author.id);
                    query.delete('author');
                }
                dispatch(getLettersList(query.toString()));
                break;
            case 'articles':
                query = makeUrlParams({
                    ...filters,
                    page: Number(articlesList.page) + 1,
                });
                if (filters.date_from) {
                    query.set(
                        'change_date_gte',
                        toFilterDate(filters.date_from),
                    );
                }
                if (filters.date_to) {
                    query.set('change_date_lte', toFilterDate(filters.date_to));
                }
                dispatch(getArticlesList(query.toString()));
                break;
            case 'media':
                query = makeUrlParams({
                    ...filters,
                    page: Number(mediaList.page) + 1,
                });
                dispatch(getMediaAttachmentsList(query.toString()));
                break;
            default:
                return;
        }
    };

    return (
        <div className={styles.searchResult}>
            {!fetching && !totalResults && (
                <span className={styles.emptyResult}>Ничего не найдено</span>
            )}
            {fetching && <Loading />}
            {!fetching && (
                <div>
                    {articlesList && !!articlesList.count && (
                        <ResultGroupComponent
                            title={`Статьи (${articlesList.count})`}
                            total={articlesList.count}
                            items={articlesList.results}
                            highlightText={filters?.q}
                            loadMore={() => loadMore('articles')}
                            closeResults={closeResults}
                            {...GROUPS.articles}
                        />
                    )}
                    {lettersList && !!lettersList.count && (
                        <ResultGroupComponent
                            title={`Информационные письма (${lettersList.count})`}
                            total={lettersList.count}
                            items={lettersList.results}
                            highlightText={filters?.q}
                            loadMore={() => loadMore('letters')}
                            closeResults={closeResults}
                            {...GROUPS.letters}
                        />
                    )}
                    {mediaList && !!mediaList.count && (
                        <ResultGroupComponent
                            title={`Документы и медиа (${mediaList.count})`}
                            total={mediaList.count}
                            items={mediaList.results}
                            highlightText={filters?.q}
                            loadMore={() => loadMore('media')}
                            closeResults={closeResults}
                            {...GROUPS.media}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchResultsComponent;
