import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_ALL_DRAFT_SAVES_START,
    GET_ALL_DRAFT_SAVES_SUCCESS,
    GET_ALL_DRAFT_SAVES_FAILED,
    GET_DRAFT_SAVE_START,
    GET_DRAFT_SAVE_SUCCESS,
    GET_DRAFT_SAVE_FAILED,
    CREATE_DRAFT_SAVE_START,
    CREATE_DRAFT_SAVE_SUCCESS,
    CREATE_DRAFT_SAVE_FAILED,
    UPDATE_DRAFT_SAVE_START,
    UPDATE_DRAFT_SAVE_SUCCESS,
    UPDATE_DRAFT_SAVE_FAILED,
    DELETE_DRAFT_SAVE_START,
    DELETE_DRAFT_SAVE_SUCCESS,
    DELETE_DRAFT_SAVE_FAILED,
} from '../actions/actionTypes';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';

function* handleGetAllDraftSaves(action) {
    try {
        const { objectType } = action.payload;
        const url = `${API_DOMAIN}/temp_storage/drafts/?object_type=${objectType}&page_size=10`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_ALL_DRAFT_SAVES_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_ALL_DRAFT_SAVES_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_ALL_DRAFT_SAVES_FAILED, error });
    }
}

function* handleGetDraftSave(action) {
    try {
        const {
            objectType,
            objectId,
            no_object_id,
            author_id,
        } = action.payload;

        let reqParam = '';

        if (objectId) {
            reqParam = `object_id=${objectId}`;
        }

        if (no_object_id && author_id) {
            reqParam = `no_object_id=true&author_id=${author_id}`;
        }

        const url = `${API_DOMAIN}/temp_storage/drafts/?object_type=${objectType}&${reqParam}&page_size=1`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_DRAFT_SAVE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_DRAFT_SAVE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_DRAFT_SAVE_FAILED, error });
    }
}

function* handleCreateDraftSave(action) {
    try {
        const url = `${API_DOMAIN}/temp_storage/drafts/`;
        const data = yield call(request.post, url, action.payload);
        if (data) {
            return yield put({
                type: CREATE_DRAFT_SAVE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: CREATE_DRAFT_SAVE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_DRAFT_SAVE_FAILED, error });
    }
}

function* handleUpdateDraftSave(action) {
    try {
        const { id, obj } = action.payload;
        const url = `${API_DOMAIN}/temp_storage/drafts/${id}/`;
        const data = yield call(request.put, url, obj);
        if (data) {
            return yield put({
                type: UPDATE_DRAFT_SAVE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: UPDATE_DRAFT_SAVE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_DRAFT_SAVE_FAILED, error });
    }
}

function* handleDeleteDraftSave(action) {
    try {
        const { id, cb } = action.payload;
        const url = `${API_DOMAIN}/temp_storage/drafts/${id}/`;
        const data = yield call(request.delete, url);
        if (data) {
            cb && cb();
            return yield put({
                type: DELETE_DRAFT_SAVE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: DELETE_DRAFT_SAVE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: DELETE_DRAFT_SAVE_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_ALL_DRAFT_SAVES_START, handleGetAllDraftSaves);
    yield takeLatest(GET_DRAFT_SAVE_START, handleGetDraftSave);
    yield takeLatest(CREATE_DRAFT_SAVE_START, handleCreateDraftSave);
    yield takeLatest(UPDATE_DRAFT_SAVE_START, handleUpdateDraftSave);
    yield takeLatest(DELETE_DRAFT_SAVE_START, handleDeleteDraftSave);
}
