import {
    can_approval_page_view,
    can_task_page_view,
    can_user_page_view,
    can_new_create,
    can_article_on_approval,
    can_article_on_actualization,
    can_article_agreed_by_user,
    can_report_page_view,
    can_function_page_view,
    can_trash_page_view,
    can_bag_report_page_view,
    can_request_info_page_view,
} from './permissions';

export const DOMAIN = `${process.env.REACT_APP_API_HOST}`;
export const API_DOMAIN = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}`;
export const ADFS_CALLBACK = process.env.REACT_APP_ADFS_CALLBACK;
export const ADFS_URL_LOGIN =
    'https://sts2.x5.ru/adfs/oauth2/authorize/?response_type=id_token' +
    '&client_id=' +
    process.env.REACT_APP_CLIENT_ID +
    '&redirect_uri=' +
    ADFS_CALLBACK;
export const TEST_HOST = 'raketa';
export const LOCAL_HOST = 'localhost';

export const NOTIFICATIONS_DURATION = 5000;

export const APPROVAL = 'approval_im';
export const FIN_APPROVAL = 'fin_approval_im';

export const PAGE_SIZE = 10;
export const ALL_TABLES_SAVE_KEY = 'tableData';
export const COMMENTS_SIZE = 5;
export const ADMIN_COMMENTS_SIZE = 5;
export const PAGE_ALL_SIZES = [5, 10, 20, 50, 100];
export const VIDEO_TYPES = ['mp4', 'webm', 'ogg'];
export const NEWS_SLIDER_ITEMS = [
    { key: 0, title: 'Описание', value: 0 },
    { key: 1, title: 'Доступ к просмотру', value: 1 },
];
export const NEWS_TYPES_ARRAY = [
    { key: 0, title: 'Федерально', value: 'federal' },
    { key: 1, title: 'Регионально', value: 'regional' },
];
export const FEED_TYPES = {
    federal: 'Федерально',
    regional: 'Регионально',
};
export const FEED_FILTER = [
    { key: 0, title: 'Все', value: '' },
    { key: 1, title: 'База знаний', value: 'base' },
    { key: 2, title: 'Новости', value: 'news' },
    { key: 3, title: 'Проекты', value: 'project' },
];
export const FAVOURITE_FILTER = [
    {
        key: 0,
        title: 'Инф. письма',
        value: '/info_messages/favourites',
        url: '/info_messages/',
    },
    {
        key: 1,
        title: 'База знаний',
        value: '/articles/favourites',
        filter: { type: 'article' },
        url: '/articles/',
    },
    {
        key: 2,
        title: 'Новости',
        value: '/articles/news/tape',
        filter: { is_favorite: true },
        url: '/articles/news/',
    },
    {
        key: 3,
        title: 'Проекты',
        value: '/articles/favourites',
        filter: { type: 'project' },
        url: '/articles/',
    },
];
export const LETTERS_FILTER = [
    { key: 0, title: 'Все', value: '' },
    { key: 1, title: 'Входящие', value: 'incoming' },
    { key: 2, title: 'Исходящие', value: 'outgoing' },
    {
        key: 3,
        title: 'Исходящие (не опубликованные)',
        value: 'outgoing_not_published',
    },
];
export const KNOWLEDGE_FILTER = [
    { key: 0, title: 'Все', value: 'all' },
    { key: 1, title: 'Проекты', value: 'project' },
    { key: 2, title: 'Статьи', value: 'article' },
];
export const ROLES_FILTER = [
    { key: 0, title: 'Все', value: '' },
    { key: 1, title: 'Пользователь портала', value: 'staff' },
    { key: 2, title: 'Администратор портала', value: 'admin' },
    { key: 3, title: 'Суперадмин', value: 'super_admin' },
];
export const LEADER_FILTER = [
    { key: 0, title: 'Все', value: '' },
    { key: 1, title: 'Руководитель территории', value: 'territory`' },
    { key: 2, title: 'Руководитель макрорегиона', value: 'macro' },
    { key: 3, title: 'Руководитель дивизиона', value: 'division' },
    { key: 4, title: 'Руководитель кластера', value: 'cluster' },
    { key: 5, title: 'Руководитель магазина', value: 'werk' },
    { key: 6, title: 'Не руководитель', value: 'not_leader' },
];
export const ASUZ_STATUS_FILTER = [
    { key: 0, title: 'Все', value: '', filter: {} },
    {
        key: 1,
        title: 'Новая',
        value: 'new',
        filter: { is_create_asuz: false },
    },
    {
        key: 2,
        title: 'Отправлена в АСУЗ',
        value: 'asuz',
        filter: { is_create_asuz: true, is_rejected: false },
    },
    {
        key: 3,
        title: 'Отменена',
        value: 'rejected',
        filter: { is_rejected: true },
    },
];
export const ERRORS_STATE_FILTER = [
    { key: 0, title: 'Все', value: '' },
    { key: 1, title: 'Обработано', value: 'true' },
    { key: 2, title: 'Не обработано', value: 'false' },
];
export const ERRORS_TYPE_FILTER = [
    { key: 0, title: 'Все', value: '' },
    { key: 1, title: 'Ошибка в содержании статьи/ИП', value: 'content' },
    { key: 2, title: 'Другая ошибка', value: 'another' },
];
export const DRAFTS_FILTER = [
    { key: 0, title: 'Все', value: '' },
    { key: 1, title: 'Инфо.письмо', value: 'info' },
    { key: 2, title: 'Статья', value: 'article' },
    { key: 3, title: 'Запросы ИП', value: 'request_info_messages' },
];
export const ERROR_TYPES = ERRORS_TYPE_FILTER.reduce(
    (list, t) => ({
        ...list,
        [t.value]: t.title,
    }),
    {},
);
export const NEWS_ADDRESS_ARRAY = [
    'от ДМ и выше',
    'от ДК и выше',
    'от ДД и выше',
    'от ДМР и выше',
    'Адресная программа',
];
export const UTC_OFFSET = '+0300';
export const ATTACHMENT_COLORS_CLASSNAME = {
    orange: {
        bg: 'orangeBg',
        iconBg: 'orangeBgIconBlock',
        iconColor: 'orangeIconColor',
        extColor: 'orangeTextExt',
    },
    blue: {
        bg: 'blueBg',
        iconBg: 'blueBgIconBlock',
        iconColor: 'blueIconColor',
        extColor: 'blueTextExt',
    },
};

export const NAVBAR_ROUTES = [
    { title: 'Главная', url: '/main', icon: 'rocket' },
    { title: 'Новости', url: '/feed', icon: 'news' },
    { title: 'База знаний', url: '/knowledgebase/categories', icon: 'course' },
    { title: 'Информ. письма', url: '/letter', icon: 'letter' },
    { title: 'Видео', url: '/videos', icon: 'media' },
    { title: 'Избранное', url: '/favorites', icon: 'star' },
    {
        title: 'Для инициаторов ИП',
        url: '/request',
        icon: 'fileLines',
        withInitIndicator: true,
        permission: can_request_info_page_view,
    },
    {
        title: 'Задачи',
        url: '/asuz',
        icon: 'list',
        permission: can_task_page_view,
    },
    {
        title: 'На согласовании',
        url: '/onapproval',
        icon: 'check',
        permission: can_approval_page_view,
    },
    {
        title: 'Мои статьи и ИП',
        url: '/articles-in-progress',
        icon: 'edit',
        dependsOnArticleCount: true,
    },
    { title: 'Профиль', url: '/profile', icon: 'person' },
    {
        title: 'Отчеты',
        url: '/reports',
        icon: 'book',
        permission: can_report_page_view,
    },
    {
        title: 'Пользователи',
        url: '/users',
        icon: 'friends',
        permission: can_user_page_view,
    },
    { title: 'Консультация юриста', url: '/consultant', icon: 'education' },
];

export const NAVBAR_ROUTES_EXTRA = [
    // { title: 'Настройки', url: '/settings', icon: 'cog' },
    {
        title: 'Сообщения об ошибках',
        url: '/errors',
        icon: 'info',
        permission: can_bag_report_page_view,
        withIndicator: true,
    },
    {
        title: 'Функции',
        url: '/functions',
        icon: 'cog',
        permission: can_function_page_view,
    },
    {
        title: 'Таблица новостей',
        url: '/news',
        icon: 'news',
        permission: can_new_create,
    },
    {
        title: 'Автосохранения',
        url: '/drafts',
        icon: 'download',
        permission: can_trash_page_view,
    },
    {
        title: 'Корзина',
        url: '/basket',
        icon: 'trash',
        permission: can_trash_page_view,
    },
];

export const BREAKPOINTS = {
    smallMobile: 320,
    mediumMobile: 480,
    tablet: 768,
    landscapeTablet: 1024,
    laptop: 1220,
    mediumDesktop: 1440,
    bigDesktop: 1920,
};

export const PROD_AWS_URL = '/idealstore-bucket';
export const TEST_AWS_URL =
    'https://ideal-store-s3.s3.eu-north-1.amazonaws.com';
export const X5_AWS_URL = 'msk-nsg-s3.x5.ru:8082/';

export const APPROVALS_ROLES = {
    from_operation: 'Эксперт от ИнфоЦентра',
    from_teach: 'Эксперт от обучения',
    from_function: 'Эксперт от функции',
};

export const APPROVALS_STATUSES = {
    draft: 'Еще не отправлено на согласование',
    on_approval: 'Ожидает согласования',
    rejected: 'Отклонено',
    agreed: 'Согласовано',
    published: 'Опубликовано',
    on_revision: 'Отправлено на доработку',
    actualization: 'На актуализации',
};

export const ARTICLE_STATUSES_DATA = {
    draft: {
        url: 'on_approval',
        notify: 'Статья передана на согласование',
    },
    on_approval: {
        url: 'agreed',
        notify: null,
    },
    agreed: {
        url: 'published',
        notify: 'Статья опубликована',
    },
    on_revision: {
        url: 'on_approval',
        notify: 'Статья передана на согласование',
    },
    published: {
        url: 'on_actualization',
        notify: 'Статья передана на актуализацию',
    },
    rejected: {
        url: 'on_approval',
        notify: 'Статья передана на согласование',
    },
    on_actualization: {
        url: 'on_approval',
        notify: 'Статья передана на согласование',
    },
};

export const LETTER_STATUSES_DATA = {
    draft: {
        url: 'on_approval',
        notify: 'ИП передано на согласование',
    },
    on_approval: {
        url: 'agreed',
        notify: null,
    },
    on_revision: {
        url: 'on_approval',
        notify: 'Письмо передано на согласование',
    },
    agreed: {
        url: 'published',
        notify: 'ИП опубликовано',
    },
    published: {
        url: 'on_revision',
        notify: 'Письмо передано на доработку',
    },
    rejected: {
        url: 'on_approval',
        notify: 'Статья передана на согласование',
    },
};

export const STATUS = {
    DRAFT: 'draft',
    ON_APPROVAL: 'on_approval',
    REJECTED: 'rejected',
    AGREED: 'agreed',
    PUBLISHED: 'published',
    ON_REVISION: 'on_revision',
    ON_ACTUALIZATION: 'on_actualization',
};

export const STATUS_TEXT = {
    [STATUS.DRAFT]: 'Черновик',
    [STATUS.ON_APPROVAL]: 'На согласовании',
    [STATUS.REJECTED]: 'Отклонено',
    [STATUS.AGREED]: 'Согласовано',
    [STATUS.PUBLISHED]: 'Опубликовано',
    [STATUS.ON_REVISION]: 'Доработать',
    [STATUS.ON_ACTUALIZATION]: 'На актуализации',
};

export const ARTICLE_BTN_TEXT = {
    [STATUS.DRAFT]: 'Отправить на согласование',
    [STATUS.ON_APPROVAL]: 'Согласовать за',
    [STATUS.ON_REVISION]: 'Отправить на согласование',
    [STATUS.ON_ACTUALIZATION]: 'Отправить на согласование',
    [STATUS.AGREED]: 'Опубликовать',
    [STATUS.PUBLISHED]: 'Требуется актуализация',
    [STATUS.REJECTED]: 'Отправить на согласование',
};

export const ARTICLE_ACTION_PERMISSION = {
    [STATUS.DRAFT]: can_article_on_approval,
    [STATUS.ON_APPROVAL]: can_article_agreed_by_user,
    [STATUS.ON_REVISION]: can_article_on_approval,
    [STATUS.ON_ACTUALIZATION]: can_article_on_approval,
    [STATUS.AGREED]: can_article_on_approval,
    [STATUS.PUBLISHED]: can_article_on_actualization,
    [STATUS.REJECTED]: can_article_on_approval,
};

export const STATUSES_TO_AGREE = [
    STATUS.DRAFT,
    STATUS.ON_APPROVAL,
    STATUS.ON_ACTUALIZATION,
    STATUS.ON_REVISION,
    STATUS.REJECTED,
];

export const APPLICATION_ROLES = [
    { key: 'admin', title: 'Администратор портала', value: 'admin' },
    { key: 'staff', title: 'Пользователь портала', value: 'staff' },
    { key: 'super_admin', title: 'Суперадмин', value: 'super_admin' },
];

export const APPLICATION_ROLE_NAMES = {
    admin: 'Администратор портала',
    staff: 'Пользователь портала',
    super_admin: 'Суперадмин',
};

export const DC_CODES = [
    { key: 1, title: 'D1 - Пятерочка (дискаунтер)', value: 'D1' },
    { key: 2, title: 'K1 - Франчайзи Пятерочка (дискаунтер)', value: 'K1' },
    { key: 3, title: 'A1 - Алкомаркет Пятьница (дискаунтер)', value: 'A1' },
    { key: 4, title: 'T1 - Налету СелфМаркет (дискаунтер)', value: 'T1' },
    { key: 5, title: 'DS - Пятерочка (darkstore)', value: 'DS' },
];

export const ENTERPRISE_STRUCTURE_TYPES = {
    retail_chain: 'Центральный офис ТС5',
    territory: 'Территория',
    macro: 'Макрорегион',
    division: 'Дивизион',
    cluster: 'Кластер',
    market: 'Магазин',
};

export const SEARCH_FILTERS_DEFAULT = {
    page: 1,
    page_size: 4,
    q: null,
    type: {
        articles: true,
        letters: true,
        projects: true,
    },
    source: {
        title: true,
        body: true,
        attach: true,
    },
    functions: [],
    date_from: null,
    date_to: null,
};
export const ASUZ_TYPE_CODES = {
    300: 'К исполнению',
    299: 'К исполнению',
    298: 'К ознакомлению',
    296: 'Отчёт к ознакомлению',
};
export const DIR_RE = /-dir@x5\.ru/gi;
export const DIR_ERROR =
    'Доступ к порталу под неперсонафицированной учетной записью доступ только из корпоративной сети';
export const CRON_LOCALE_RU = {
    everyText: 'каждый',
    emptyMonths: 'каждый месяц',
    emptyMonthDays: 'каждый день месяца',
    emptyMonthDaysShort: 'день месяца',
    emptyWeekDays: 'каждый день недели',
    emptyWeekDaysShort: 'день недели',
    emptyHours: 'каждый час',
    emptyMinutes: 'каждую минуту',
    emptyMinutesForHourPeriod: 'каждый',
    yearOption: 'год',
    monthOption: 'месяц',
    weekOption: 'неделя',
    dayOption: 'день',
    hourOption: 'час',
    minuteOption: 'минута',
    rebootOption: 'перезагрузка',
    prefixPeriod: 'Каждый(ую)',
    prefixMonths: 'в',
    prefixMonthDays: 'в',
    prefixWeekDays: 'по',
    prefixWeekDaysForMonthAndYearPeriod: 'и',
    prefixHours: 'в',
    prefixMinutes: ':',
    prefixMinutesForHourPeriod: 'в',
    suffixMinutesForHourPeriod: 'минут(ы)',
    errorInvalidCron: 'Неверное cron выражение',
    clearButtonText: 'Очистить',
    weekDays: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
    ],
    months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ],
    altWeekDays: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
    altMonths: [
        'ЯНВ',
        'ФЕВ',
        'МАР',
        'АПР',
        'МАЙ',
        'ИЮН',
        'ИЮЛ',
        'АВГ',
        'СЕН',
        'ОКТ',
        'НОЯ',
        'ДЕК',
    ],
};

export const ARTICLE_TYPES = [
    { key: 1, title: 'Статья', value: 'article' },
    { key: 2, title: 'Проект', value: 'project' },
];

export const ARTICLE_FILTER_STATUSES = [
    { key: 1, id: 1, title: 'Опубликовано', value: 'published' },
    { key: 2, id: 2, title: 'Согласовано', value: 'agreed' },
    { key: 3, id: 3, title: 'На согласовании', value: 'on_approval' },
    { key: 4, id: 4, title: 'Черновик', value: 'draft' },
    { key: 5, id: 5, title: 'На актуализации', value: 'on_actualization' },
];

export const INFO_FILTER_STATUSES = [
    { key: 1, id: 1, title: 'Опубликовано', value: 'published' },
    { key: 2, id: 2, title: 'Согласовано', value: 'agreed' },
    { key: 3, id: 3, title: 'На согласовании', value: 'on_approval' },
    { key: 4, id: 4, title: 'Черновик', value: 'draft' },
];
